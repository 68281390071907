import React from "react"
import BasicLayout from "../components/layout/BasicLayout/BasicLayout"
import SEO from "../components/seo"
import AgencyPage from "../components/AgencyPage/AgencyPage"

const Agency = () => (
  <BasicLayout stickyBtn={false}>
    <SEO title="Agency" />
    <AgencyPage />
  </BasicLayout>
)

export default Agency
