import React from "react"
import MultiCarousel from "../MultiCarousel/MultiCarousel"
import AgencyCarouselItem from "./AgencyCarouselItem/AgancyCarouselItem"

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 2000 },
    items: 1,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 2000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 577 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 576, min: 0 },
    items: 1,
  },
}

const AgencyCarousel = ({ className, agency }) => {
  return (
    <div className={className}>
      <MultiCarousel setup={responsive} buttonsIn={false}>
        {agency.map((item, index) => (
          <AgencyCarouselItem key={index} photo={item} />
        ))}
      </MultiCarousel>
    </div>
  )
}

export default AgencyCarousel
