import React from "react"
import Image from "../../../image"
import classes from "../../MultiCarousel/MultiCarousel.module.scss"

const AgencyCarouselItem = ({ photo }) => {
  return (
    <Image
      src={photo}
      alt="Photo"
      className={classes.agencyPhoto}
      draggable={false}
    />
  )
}

export default AgencyCarouselItem
